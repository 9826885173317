import { makeStyles, Grid } from '@material-ui/core'
import React from 'react'
import { FormattedNumber } from 'react-intl'

import ButtonLink from '@aletheia/common/components/ButtonLink'
import FormattedCurrency from '@aletheia/common/components/__legacy/UI/FormattedCurrency'
import { useGetPaymentStatsAggregateQueryWithReducer } from '@aletheia/graphql'

import { SansSerifTypography } from '../../globals/theme'
import Section from './Section'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    textAlign: 'center',
  },
  overviewWidgetWrapper: {},
  actions: {
    textAlign: 'center',
    marginTop: theme.spacing(6),
  },
}))

export const ProgressReport: React.FC<{ numPaidOutGrants: number }> = ({
  numPaidOutGrants,
}) => {
  const classes = useStyles()
  const [PaymentStats] = useGetPaymentStatsAggregateQueryWithReducer()

  return (
    <div className={classes.root}>
      <Section title="Our progress in numbers" maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} className={classes.overviewWidgetWrapper}>
            <SansSerifTypography variant="h4" gutterBottom>
              {PaymentStats?.totalDonations && (
                <>
                  <FormattedCurrency
                    amount={PaymentStats.totalDonations / 1_000_000}
                    currencyCode={'USD'}
                    minimumFractionDigits={0}
                    maximumFractionDigits={1}
                  />
                  M
                </>
              )}
            </SansSerifTypography>
            <SansSerifTypography gutterBottom>
              donated via EA Funds since inception
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.overviewWidgetWrapper}>
            <SansSerifTypography variant="h4" gutterBottom>
              <FormattedNumber
                value={numPaidOutGrants}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </SansSerifTypography>
            <SansSerifTypography gutterBottom>
              grants paid out
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.overviewWidgetWrapper}>
            <SansSerifTypography variant="h4" gutterBottom>
              {PaymentStats?.numDonorsLastYear && (
                <FormattedNumber
                  value={PaymentStats.numDonorsLastYear}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              )}
            </SansSerifTypography>
            <SansSerifTypography gutterBottom>
              donors in the last 12 months
            </SansSerifTypography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.overviewWidgetWrapper}>
            <SansSerifTypography variant="h4" gutterBottom>
              8.3/10
            </SansSerifTypography>
            <SansSerifTypography gutterBottom>
              average donor satisfaction
            </SansSerifTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={9} sm={6} md={4}>
            <ButtonLink
              href="/stats"
              variant="contained"
              color="default"
              fullWidth
            >
              View more detailed stats
            </ButtonLink>
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}
