import {
  makeStyles,
  Container,
  TypographyProps,
  ContainerProps,
} from '@material-ui/core'
import React from 'react'
import widont from 'widont'

import { SansSerifTypography } from '../../globals/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    overflow: 'hidden',
  },
  headingWrapper: {
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {},
  sectionSubtitle: {},
  sectionDescription: {},
}))

type SectionProps = {
  title?: string
  subtitle?: string
  description?: string | React.ReactNode
  children: React.ReactNode
  subtitleVariant?: TypographyProps['variant']
  titleVariant?: TypographyProps['variant']
  maxWidth?: ContainerProps['maxWidth']
}

export const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  description,
  children,
  titleVariant,
  subtitleVariant,
  maxWidth,
}) => {
  const classes = useStyles()
  if (!titleVariant) titleVariant = 'h3'
  if (!subtitleVariant) subtitleVariant = 'h4'

  return (
    <div className={classes.root}>
      <Container fixed maxWidth={maxWidth}>
        <div className={classes.headingWrapper}>
          {title && (
            <SansSerifTypography
              variant={titleVariant}
              className={classes.sectionTitle}
              gutterBottom
            >
              {widont(title)}
            </SansSerifTypography>
          )}
          {subtitle && (
            <SansSerifTypography
              gutterBottom
              className={classes.sectionSubtitle}
              variant={subtitleVariant}
            >
              {widont(subtitle)}
            </SansSerifTypography>
          )}
          {description && (
            <SansSerifTypography
              className={classes.sectionDescription}
              gutterBottom
            >
              {description}
            </SansSerifTypography>
          )}
        </div>
        {children}
      </Container>
    </div>
  )
}

export default Section
