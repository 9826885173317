import { Typography, makeStyles, Grid, Container } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import widont from 'widont'

import ButtonLink from '@aletheia/common/components/ButtonLink'
import { useBackgroundImage } from '@aletheia/common/components/ContentfulImage/background'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.dark,
    minHeight: 500,
    [theme.breakpoints.only('xs')]: {
      backgroundPosition: 'center 65%',
    },
    [theme.breakpoints.only('sm')]: {
      backgroundPosition: 'center 65%',
    },
    '@media(min-width: 700px)': {
      backgroundPosition: 'center 75%',
    },
    [theme.breakpoints.only('md')]: {
      backgroundPosition: 'center 75%',
    },
    [theme.breakpoints.only('lg')]: {
      height: 700,
      backgroundPosition: 'center 79%',
    },
    [theme.breakpoints.up('xl')]: {
      height: 900,
      backgroundPosition: 'center 76%',
    },
    '@media(min-width: 2000px)': {
      height: 1000,
      backgroundPosition: 'center 79%',
    },
    borderBottom: '7px solid #1B5266',
  },
  heading: {
    marginBottom: theme.spacing(2),
    color: '#FFF',
    textShadow:
      '0px 4px 32px rgba(0, 0, 0, 0.3), 0px 1px 6px rgba(0, 0, 0, 0.25)',
    fontSize: '3.5rem',
  },
  subtitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '1.2rem',
    color: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
    textShadow:
      '0px 4px 32px rgba(0, 0, 0, 0.3), 0px 1px 6px rgba(0, 0, 0, 0.25)',
  },
  cta: {
    boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.19)',
    fontSize: '1.2rem',
  },
  overlay: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(11, 134, 155, 0.22)',
  },
  content: {
    zIndex: 2,
    postition: 'relative',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '25px 60px 60px',
  },
}))

export const HeroUnit: React.FC = () => {
  const classes = useStyles()
  const backgroundImage = useBackgroundImage('/landing/hero-bg.jpg')
  return (
    <div className={classnames(classes.root, backgroundImage)}>
      <div className={classes.overlay} />
      <Container className={classes.content} maxWidth="lg">
        <Typography variant="h2" component="h1" className={classes.heading}>
          {widont('We fund outstanding projects focused on social impact')}
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {widont(
            'Whether an individual, organisation or other entity, we’re eager to fund great ideas and great people.',
          )}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item>
            <ButtonLink
              className={classes.cta}
              href="/apply-for-funding"
              variant="contained"
              color="primary"
              size="large"
            >
              Get Funded
            </ButtonLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
export default HeroUnit
