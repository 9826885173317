import { Grid, Typography, makeStyles } from '@material-ui/core'
import Image from 'next/image'
import React from 'react'

import ButtonLink from '@aletheia/common/components/ButtonLink'

import { SansSerifTypography } from '../../globals/theme'
import { Section } from './Section'

const useStyles = makeStyles((theme) => ({
  column: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}))

export const GetFundedList: React.FC = () => {
  const classes = useStyles()

  return (
    <Section
      title={'Have a high-impact project that needs funding?'}
      description="If you have a project or organization that you think will improve the world that’s a good fit for one of our funds, we encourage you to apply."
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} className={classes.column}>
          <div>
            <Image
              src={'/landing/size.png'}
              alt="Illustration"
              width={300}
              height={300}
            />
          </div>
          <SansSerifTypography variant="h6">
            Usually between $5,000-100,000,
          </SansSerifTypography>
          <SansSerifTypography>
            but can be as low as $1,000 or higher than $300,000.
          </SansSerifTypography>
        </Grid>

        <Grid item xs={12} md={4} className={classes.column}>
          <div>
            <Image
              src={'/landing/world.png'}
              alt="Illustration"
              width={300}
              height={300}
            />
          </div>
          <SansSerifTypography variant="h6">
            Open to all entity types,
          </SansSerifTypography>
          <SansSerifTypography>
            including; individuals, non-profit organizations & academic
            institutions.
          </SansSerifTypography>
        </Grid>

        <Grid item xs={12} md={4} className={classes.column}>
          <div>
            <Image
              src={'/landing/form.png'}
              alt="Illustration"
              width={300}
              height={300}
            />
          </div>
          <SansSerifTypography variant="h6">
            Quick &amp; easy to apply
          </SansSerifTypography>
          <SansSerifTypography>
            for and usually granted within 21 days.
          </SansSerifTypography>
        </Grid>
      </Grid>

      <Typography align="center" gutterBottom>
        <ButtonLink
          href="/apply-for-funding"
          variant="contained"
          color="primary"
          size="large"
        >
          Get Funded
        </ButtonLink>
      </Typography>
    </Section>
  )
}
