import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { IAsset, ILink, isAsset } from '../../lib/contentful/types'

type ContentfulImageProps = {
  image: IAsset | ILink<'Asset'> | string | undefined
  sizes?: string
  widths?: number[]
  className?: string
  gutterBottom?: boolean
  square?: boolean
  aspectRatio?: number
  lazy?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
    '&.gutter-bottom': {
      marginBottom: theme.spacing(2),
    },
  },
}))

const IMAGE_WIDTHS = [300, 480, 600, 800, 1024, 1200, 1920, 2550]

const DEFAULT_SIZES = '(min-width: 600px) 60vw, (min-width: 1200px) 40vw, 100vw'

const ContentfulImage: React.FC<
  ContentfulImageProps &
    Omit<
      React.HTMLProps<HTMLImageElement>,
      'src' | 'placeholder' | 'crossOrigin'
    >
> = ({
  image,
  sizes = DEFAULT_SIZES,
  widths = IMAGE_WIDTHS,
  className,
  gutterBottom,
  square,
  aspectRatio,
  lazy = true,
  ...imageProps
}) => {
  const classes = useStyles()
  if (!image) return null
  let url: string | undefined, title: string | undefined
  if (typeof image === 'string') url = image

  if (isAsset(image)) {
    url = image.fields.file.url
    title = image.fields.title
  } else {
    if (!title && imageProps.alt) {
      title = imageProps.alt
    }
  }

  // If our smallest image size is > 200px wide, use a placeholder
  // If not, don't bother
  const placeholderSize = widths[0] > 200 ? 100 : false

  if (!url) return null

  const ar = square ? 1 : aspectRatio

  const srcsetVals = widths.map((width) => ({
    url: `${url}?w=${width}${ar ? `&h=${Math.ceil(width * ar)}&fit=fill` : ''}`,
    widthVal: `${width}w`,
    width,
  }))
  const srcSet = srcsetVals
    .map(({ url, widthVal }) => `${url} ${widthVal}`)
    .join(', ')

  return (
    <LazyLoadImage
      visibleByDefault={!lazy}
      alt={title}
      title={title}
      src={srcsetVals[0].url}
      srcSet={srcSet}
      sizes={sizes}
      className={classnames(classes.root, className, {
        'gutter-bottom': gutterBottom,
      })}
      placeholderSrc={placeholderSize ? `${url}?w=100` : undefined}
      effect="blur"
      {...imageProps}
    />
  )
}

export default ContentfulImage
