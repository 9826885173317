import { makeStyles, Box, Paper, Grid } from '@material-ui/core'
import Link from 'next/link'
import React from 'react'

import ButtonLink from '@aletheia/common/components/ButtonLink'
import ContentfulImage from '@aletheia/common/components/ContentfulImage'

import { IFund } from '../../contentful'
import { SansSerifTypography } from '../../globals/theme'
import Section from './Section'

const fundOrgs = [
  {
    slug: 'global-development',
    name: 'Global Development Fund',
    description: `Funding to outstanding, evidence-based opportunities to provide better access to healthcare and economic development where it's needed most.`,
    usually_supports: 'Established organizations',
    decides_using: 'Scientific evidence and fund managers’ judgment',
  },
  {
    slug: 'animal-welfare',
    name: 'Animal Welfare Fund',
    description: `Funding to organizations and projects that will help alleviate the suffering of millions or billions of animals.`,
    usually_supports: 'Early-stage and established organizations',
    decides_using: 'Organizational track record and fund managers’ judgment',
  },
  {
    slug: 'far-future',
    name: 'Long-Term Future Fund',
    description: `Funding to people or projects that aim to improve the long-term future, such as by reducing risks from artificial intelligence and engineered pandemics.`,
    usually_supports:
      'Individuals and organizations (includes speculative opportunities)',
    decides_using: 'Fund managers’ judgment',
  },
  {
    slug: 'ea-community',
    name: 'EA Infrastructure Fund',
    description: `Funding to organizations or people that aim to grow or improve the effective altruism community.`,
    usually_supports:
      'Organizations and early-stage projects (includes speculative opportunities)',
    decides_using: 'Fund managers’ judgment',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: theme.spacing(-6),
  },
  fundContentWrapper: { padding: theme.spacing(4) },
  fundName: {
    textDecoration: 'none',
    '.MuiTypography-h4': {
      marginTop: 0,
    },
    '&:hover .MuiTypography-h4': {
      color: theme.palette.primary.dark,
    },
  },
  fundDescription: {},
  fundNote: {
    color: theme.palette.grey[600],
  },
  fundWrapper: {
    marginBottom: theme.spacing(4),
    overflow: 'hidden',
  },
  fundImageWrapper: {
    alignSelf: 'stretch',
    '& > a': { display: 'block', height: '100%', width: '100%' },
    '& > a > span': { height: '100%', width: '100%' },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: theme.spacing(20),
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.grey[500]}`,
    },
  },
}))

export const OurFunds: React.FC<{ Funds: IFund[] }> = ({ Funds }) => {
  const classes = useStyles()

  return (
    <div id="funds" className={classes.root}>
      <Section
        title="Our funds open to you"
        description="Our fund managers allocate donations to those they believe will have the biggest impact within 
        their fund’s scope. Once you have determined which fund suits your work best, apply to it directly."
      >
        <Box>
          {fundOrgs.map((fundOrg) => {
            const Fund = Funds.find((Fund) => Fund.fields.slug === fundOrg.slug)
            const url = `/funds/${fundOrg.slug}`
            return (
              <Paper className={classes.fundWrapper} key={fundOrg.slug}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={4}
                    className={classes.fundImageWrapper}
                  >
                    <Link href={url} title={fundOrg.name}>
                      <a>
                        <ContentfulImage
                          image={Fund?.fields.bannerImage}
                          className={classes.image}
                        />
                      </a>
                    </Link>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    className={classes.fundContentWrapper}
                  >
                    <Link href={url} title={fundOrg.name}>
                      <a className={classes.fundName}>
                        <SansSerifTypography variant="h4" gutterBottom>
                          {fundOrg.name}
                        </SansSerifTypography>
                      </a>
                    </Link>
                    <SansSerifTypography
                      className={classes.fundDescription}
                      gutterBottom
                    >
                      {fundOrg.description}
                    </SansSerifTypography>
                    <SansSerifTypography className={classes.fundNote}>
                      <strong>Usually supports:</strong>&nbsp;
                      {fundOrg.usually_supports}
                    </SansSerifTypography>
                    <SansSerifTypography
                      className={classes.fundNote}
                      gutterBottom
                    >
                      <strong>Decides using:</strong>&nbsp;
                      {fundOrg.decides_using}
                    </SansSerifTypography>
                    <br />
                    {url && (
                      <Grid container spacing={3} justifyContent="center">
                        <Grid item sm={12} md={6}>
                          <ButtonLink
                            variant="contained"
                            href={url}
                            color="primary"
                            fullWidth
                          >
                            Learn more
                          </ButtonLink>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <ButtonLink
                            variant="contained"
                            href={`/grants?fund=${encodeURIComponent(
                              fundOrg.name,
                            )}`}
                            color="default"
                            fullWidth
                          >
                            View past grants
                          </ButtonLink>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            )
          })}
        </Box>
      </Section>
    </div>
  )
}
