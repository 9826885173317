import React from 'react'
import { FormattedNumber } from 'react-intl'

type FormattedCurrencyProps = {
  amount: number
  currencyCode: string
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  minimumDigitsIfDecimal?: number
  crypto?: boolean
  currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol' | 'name'
}

const FormattedCurrency: React.FC<FormattedCurrencyProps> = ({
  amount,
  currencyCode,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  minimumDigitsIfDecimal,
  crypto = false,
  currencyDisplay = 'code',
}) => {
  if (minimumDigitsIfDecimal) {
    const isCloseToInteger =
      Math.abs(amount - Math.round(amount)) <
      10 ** -(minimumDigitsIfDecimal + 1)
    if (!isCloseToInteger) {
      minimumFractionDigits = Math.max(
        minimumDigitsIfDecimal,
        minimumFractionDigits,
      )
    }
  }
  if (crypto) maximumFractionDigits = 18
  // fallback for non-standard codes that don't conform to ISO 3-letter spec (e.g. USDT)
  if (crypto || currencyCode.length !== 3)
    return (
      <>
        <FormattedNumber
          value={amount}
          minimumFractionDigits={minimumFractionDigits}
          maximumFractionDigits={maximumFractionDigits}
        />{' '}
        {currencyCode}
      </>
    )
  // regular formatter
  return (
    <FormattedNumber
      style="currency"
      currency={currencyCode}
      value={amount}
      currencyDisplay={currencyDisplay}
      minimumFractionDigits={minimumFractionDigits}
      maximumFractionDigits={maximumFractionDigits}
    />
  )
}
export default FormattedCurrency
