import { Grid, makeStyles, Link as MUILink } from '@material-ui/core'
import React from 'react'

import ButtonLink from '@aletheia/common/components/ButtonLink'

import { GWWC_DONATE_FULL_URL } from '../../globals/routes'
import Section from './Section'

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
}))

export const PartnerList: React.FC = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Section
        title="Want to donate to one of our funds?"
        description={
          <>
            If you’re looking for a high-impact opportunity to donate to we’d
            recommend you explore our partner site{' '}
            <MUILink href={GWWC_DONATE_FULL_URL}>givingwhatwecan.org</MUILink>,
            our new route for all donations.
          </>
        }
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={9} sm={6}>
            <ButtonLink
              href={GWWC_DONATE_FULL_URL}
              variant="contained"
              color="default"
              fullWidth
            >
              Head to givingwhatwecan.org for more on donating
            </ButtonLink>
          </Grid>
        </Grid>
      </Section>
    </div>
  )
}
